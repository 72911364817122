exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brand-coming-soon-tsx": () => import("./../../../src/pages/brand/coming-soon.tsx" /* webpackChunkName: "component---src-pages-brand-coming-soon-tsx" */),
  "component---src-pages-brand-index-tsx": () => import("./../../../src/pages/brand/index.tsx" /* webpackChunkName: "component---src-pages-brand-index-tsx" */),
  "component---src-pages-extension-tsx": () => import("./../../../src/pages/extension.tsx" /* webpackChunkName: "component---src-pages-extension-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-product-contentful-product-review-summary-slug-tsx": () => import("./../../../src/pages/product/{ContentfulProductReviewSummary.slug}.tsx" /* webpackChunkName: "component---src-pages-product-contentful-product-review-summary-slug-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-uninstalled-tsx": () => import("./../../../src/pages/uninstalled.tsx" /* webpackChunkName: "component---src-pages-uninstalled-tsx" */),
  "component---src-templates-recent-products-template-tsx": () => import("./../../../src/templates/recent-products-template.tsx" /* webpackChunkName: "component---src-templates-recent-products-template-tsx" */)
}

