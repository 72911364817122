import { createTheme, responsiveFontSizes } from "@mui/material";

// Declare custom color types for theme pallets
declare module "@mui/material/styles" {
  interface Affiliates {
    affiliates?: {
      amazon?: {
        tag: string,
      }
    },
  }
  interface Theme extends Affiliates {}
  interface ThemeOptions extends Affiliates {}
}

const theme = responsiveFontSizes(createTheme({
    // TODO: Dark mode support (swap pictures too? night picture with dark mode)

    // palette: {
    //   mode: 'dark',
    // },

    affiliates: {
      amazon: {
        tag: "reviewskew05-20",
      },
    },

    palette: {
      primary: {  // Used for links and other primary state stuff
        main: '#00ADB5',
      },
    },

    typography: {
      fontFamily: [
        'Heiti SC',
        'Helvetica',
        'sans-serif'
      ].join(','),
    },

    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            textTransform: "none",
          },
        },
      }, 
    },

}));

export default theme;
